import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.css'
import wNumb from 'wnumb'
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

const range = document.getElementById('points-slider2');

if(range){
noUiSlider.create(range, {

    range: {
        'min': 0,
        'max': 4000
    },

    step: 500,

    // Handles start at ...
    start: [100],

    // ... must be at least 300 apart
    //margin: 300,

    // ... but no more than 600
   // limit: 600,

    // Display colored bars between handles
    connect: true,

    // Put '0' at the bottom of the slider
    direction: 'ltr',
    orientation: 'horizontal',

    // Move handle on tap, bars are draggable
    behaviour: 'tap-drag',
    tooltips: true,
    format: wNumb({
        decimals: 0
    }),

    // Show a scale with the slider
    pips: {
        mode: 'steps',
        stepped: true,
        density: 4
    }
});
}
/**
var slider = document.getElementById('slider');

noUiSlider.create(slider, {
    start: [20, 80],
    connect: true,
    range: {
        'min': 0,
        'max': 100
    }
});
 * 
 */

var moneyFormat = wNumb({
	mark: '.',
	thousand: ',',
	prefix: '$ ',
	suffix: ' p.p.'
});

const slider = document.getElementById('points-slider');

if(slider){
noUiSlider.create(slider, {
    start: [100],
    connect: true,
    range: {
        'min': 0,
        'max': 4000
    },
    step: 1,
    pips: {
        mode: 'steps',
        stepped: true,
        density: 4
    },
    behaviour: 'tap-drag',
    tooltips: true,
    format: wNumb({
        decimals: 0
    }),


});

slider.setAttribute('disabled', true);
}